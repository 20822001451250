import { applyMiddleware, combineReducers, createStore } from "redux";
import createSagaMiddleware from "redux-saga";

import * as reducers from "./reducers";
import * as selectors from "./selectors";
import { rootSaga } from "./sagas";
import * as actions from "./actions";
import { composeWithDevTools } from "redux-devtools-extension";

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
	auth: reducers.auth.reducer,
	session: reducers.session.reducer,
	ui: reducers.ui.reducer,
	cards: reducers.cards.reducer,
	deleteBuild: reducers.deleteBuild.reducer,
	notify: reducers.notify.reducer,
});

const initialState = {
	auth: reducers.auth.initialState,
	session: reducers.session.initialState,
	ui: reducers.ui.initialState,
	cards: reducers.cards.initialState,
	deleteBuild: reducers.deleteBuild.initialState,
	notify: reducers.notify.initialState,
};

export const store = createStore(
	rootReducer,
	initialState,
	composeWithDevTools(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

export { actions, selectors };
