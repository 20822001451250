import { createSelector } from "reselect";

export const state = (state => state.ui);

export const loading = createSelector(
    [state],
    (ui => ui.loading)
);

export const counter = createSelector(
    [state],
    (ui => ui.counter)
);
