import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { selectors } from 'store'

export const ProtectedRoute = ({ component: Component, ...rest }) => {
	const isAuthorized = useSelector(selectors.auth.isAuthorized)

	return (
		<Route
			{...rest}
			render={(props) =>
				isAuthorized ? (
					<Component {...props} />
				) : (
					<Redirect to='/login' />
				)
			}
		/>
	)
}
