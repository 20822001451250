import qr from 'assets/icons/qr-code.svg'

export const CardQRBtn = ({ onclickHandler }) => {
  return (
    <button
      onClick={onclickHandler}
      className='download-btn card-btn'
    >
      <img src={ qr } alt='qr icon'/>
    </button>
  )
}