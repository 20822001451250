import bin from 'assets/icons/bin.svg'

export const CardDeleteBtn = ({ canDelete, deleteBtnHandler }) => {
  return (<>
    { 
     canDelete && (
      <button
        className='card-btn delete-btn'
        onClick={deleteBtnHandler}
      >
        <img src={ bin } alt='trash icon'/>
      </button>)
    }
    </>
  )
}