export const initialState = {
    error: null,
    loading: false,
    isInitialized: false,
    spaces: [],
    types: {},
    statuses: [],
    currentSpaceId: null,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SESSION/INIT_APP_INIT':
            return {
                ...state,
                error: null,
                loading: true,
            };
        case 'SESSION/INIT_APP_DONE':
            return {
                ...state,
                error: null,
                loading: false,
                isInitialized: true,
            };
        case 'SESSION/INIT_APP_FAIL':
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        case 'SESSION/GET_SPACES_DONE':
            return {
                ...state,
                spaces: action.payload.data,
            };
        case 'SESSION/GET_TYPES_DONE':
            return {
                ...state,
                types: action.payload.data,
            };
        case 'SESSION/SET_SPACE_ID':
            return {
                ...state,
                currentSpaceId: action.payload,
            };
        default:
            return state;
    }
}