import ios from 'assets/icons/ios.svg'
import android from 'assets/icons/android.svg'

export const CardHeadTitle = ({ name, bundle_id, space_name, platformName, version, bundle_version }) => {

  const lowerPlatformName = platformName.toLowerCase()

  const platformsIcons = {
    "ios": <img style={{marginRight: '15px'}} src={ ios } alt='ios platform'/>,
    'android': <img style={{marginRight: '15px'}} src={ android } alt='ios platform'/>,
    'aab': <img style={{marginRight: '15px'}} src={ android } alt='ios platform'/>,
    'amazon': <img style={{marginRight: '15px'}} src={ android } alt='ios platform'/>,
  }

  return (
    <div className='card-body card-head-title'>
      { platformsIcons[lowerPlatformName] }
      <div>
        <p className = 'product-title' style = {{ marginBottom:'-5px' }} > { name } <span className='product-description'>{ version } ({ bundle_version || 'No bundle version' })</span> </p>
        <p className='product-description' style = {{ marginBottom:'-5px' }}>{ bundle_id }</p>
        { space_name && <p className='product-description'>{ space_name }</p> }
      </div>
  </div>
  )
}