import { select, takeLatest } from 'redux-saga/effects'
import { actions, selectors } from '../index'
import { bindApiCall, openNotification } from './utils'
import { history } from 'utils/history'
import { api } from '../api'

function* getDeleteBuild(action) {
	try {
		const token = yield select(selectors.auth.token)

		let result = yield bindApiCall(
			actions.deleteBuild.getDeleteBuild,
			api.getDeleteBuild,
			token,
			action.payload
		)
		const app_id = yield select(selectors.cards.currentCard)

		if (
			result.data.main_page_redirect &&
			result.data.main_page_redirect === 1
		) {
			history.push(`/`)
		}
		if (result.data.main_page_redirect === 0) {
			yield bindApiCall(
				actions.cards.getById,
				api.getById,
				token,
				app_id.builds[0].app_id
			)
		}
	} catch (e) {
		console.log(e)
		openNotification('error', e.message, 'Error')
	}
}

export function* getDeleteBuildSaga() {
	yield takeLatest('CARDS/GET_DELETE_INIT', getDeleteBuild)
}
