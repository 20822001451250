import download from 'assets/icons/download.svg'

export const CardDownloadBtn = ({ downloadHref }) => {
  return (
    <a
      href = { downloadHref }
      className='download-btn card-btn'
      onClick={ (e) => e.stopPropagation() }
      target='_blank'
      rel='noreferrer'
    >
      <img src={ download } alt='donwload icon'/>
    </a>
  )
}