import { select, takeLatest } from "redux-saga/effects";
import { actions, selectors } from "../index";
import { bindApiCall, openNotification } from "./utils";

import { api } from "../api";

function* getNotifyUserChange(action) {
	try {
		const token = yield select(selectors.auth.token);

		yield bindApiCall(
			actions.notify.userChange,
			api.getNotifyUserChange,
			token,
			action.payload
		);
	} catch (e) {
		console.log(e);
		openNotification("error", e.message, "Error");
	}
}

export function* getNotifyUserChangeSaga() {
	yield takeLatest("NOTIFY/GET_USER_CHANGE_INIT", getNotifyUserChange);
}

function* getNotifySpaceChange(action) {
	try {
		const token = yield select(selectors.auth.token);

		yield bindApiCall(
			actions.notify.spaceChange,
			api.getNotifySpaceChange,
			token,
			action.payload
		);
	} catch (e) {
		console.log(e);
		openNotification("error", e.message, "Error");
	}
}

export function* getNotifySpaceChangeSaga() {
	yield takeLatest("NOTIFY/GET_SPACE_CHANGE_INIT", getNotifySpaceChange);
}
