import axios from 'axios'
import * as qs from 'qs'

window.qs = qs

const client = axios.create({
	baseURL: 'https://ipa3.testdlab.com/api/v1',
	validateStatus: (status) => {
		return status < 400
	},
})

const authHeaders = (token) => ({
	Authorization: `Bearer ${token}`,
	'Access-Control-Allow-Origin': '*',
})

export const api = {
	login: (payload) => {
		const url = 'auth/login'
		return client({
			method: 'POST',
			url,
			data: payload,
		})
	},
	check: (token) => {
		const url = '/auth/check'
		return client({
			method: 'GET',
			url,
			headers: authHeaders(token),
		})
	},
	spaces: (token) => {
		const url = '/spaces/get'
		return client({
			method: 'GET',
			url,
			headers: authHeaders(token),
		})
	},
	types: (token) => {
		const url = 'types/get'
		return client({
			method: 'GET',
			url,
			headers: authHeaders(token),
		})
	},
	getCards: (token, params) => {
		const url = `/card/get?${qs.stringify(params)}`

		return client({
			method: 'GET',
			url,
			headers: authHeaders(token),
		})
	},
	getById: (token, id) => {
		let url
		if (typeof id === 'object') {
			id[1] === 0
				? (url = `/card/${id[0]}`)
				: (url = `/card/${id[0]}?version=${id[1]}`)
		} else {
			url = `/card/${id}`
		}

		return client({
			method: 'GET',
			url,
			headers: authHeaders(token),
		})
	},

	getDeleteBuild: (token, build_id) => {
		const url = `/card/build/delete?build_id=${build_id}`

		return client({
			method: 'GET',
			url,
			headers: authHeaders(token),
		})
	},

	getNotifyUserChange: (token, notify) => {
		const url = `/notify/user_change`

		let formData = new FormData()

		formData.append('notify', notify)

		return client({
			method: 'POST',
			url,
			data: formData,
			headers: authHeaders(token),
		})
	},

	getNotifySpaceChange: (token, notify) => {
		const url = `/notify/space_change`

		return client({
			method: 'POST',
			url,
			data: notify,
			headers: authHeaders(token),
		})
	},
}
