import React, { useCallback, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'store'
import { createStructuredSelector } from 'reselect'
import { Form } from 'react-bootstrap'

const rootSelector = createStructuredSelector({
	loading: selectors.auth.loading,
	error: selectors.auth.error,
	isAuthorized: selectors.auth.isAuthorized,
})

export const LoginPage = () => {
	const emailRef = useRef()
	const passwordRef = useRef()

	const history = useHistory()
	const dispatch = useDispatch()
	const { isAuthorized } = useSelector(rootSelector)
	const onSubmit = useCallback(
		(e) => {
			e.preventDefault()

			const email = emailRef.current.value
			const password = passwordRef.current.value

			dispatch(actions.auth.login.init({ email, password }))
		},
		[dispatch]
	)

	useEffect(() => {
		if (isAuthorized) {
			history.push('/')
		}
	}, [isAuthorized, history])

	return (
		<>
			<div className='container-scroller'>
				<div className='container-fluid page-body-wrapper full-page-wrapper'>
					<div className='main-panel'>
						<div className='content-wrapper'>
							<div>
								<div className='d-flex align-items-center auth px-0'>
									<div
										className='row w-100 mx-0'
										style={{
											margin: '0 auto',
											alignItems: 'center',
											position: 'absolute',
											top: '50%',
											left: '50%',
											transform: 'translate(-50%, -50%)',
										}}
									>
										<div className='col-lg-4 mx-auto'>
											<div className='auth-form-dark text-left py-5 px-4 px-sm-5'>
												<h4
													style={{ color: '#f3f3f3' }}
												>
													Hello! let's get started
												</h4>
												<h6
													className='font-weight-light'
													style={{ color: '#f3f3f3' }}
												>
													Sign in to continue.
												</h6>
												<Form
													className='pt-3'
													onSubmit={onSubmit}
												>
													<Form.Group className='d-flex search-field'>
														<Form.Control
															type='email'
															placeholder='Username'
															size='lg'
															className='h-auto'
															ref={emailRef}
														/>
													</Form.Group>
													<Form.Group className='d-flex search-field'>
														<Form.Control
															type='password'
															placeholder='Password'
															size='lg'
															className='h-auto'
															ref={passwordRef}
														/>
													</Form.Group>
													<div className='mt-3'>
														<button
															className='btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn'
															type='submit'
														>
															SIGN IN
														</button>
													</div>
												</Form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
