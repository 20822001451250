import {select, takeLatest} from "redux-saga/effects";
import {actions, selectors} from "../index";
import {bindApiCall, openNotification} from "./utils";
import {api} from "../api";

function* getCards(action) {
    try {
    const token = yield select(selectors.auth.token);
    yield bindApiCall(actions.cards.getCards, api.getCards, token, action.payload);
    } catch (e) {
        openNotification('error', e.message, "Error");
    }
}

export function* getCardsSaga() {
    yield takeLatest('CARDS/GET_CARDS_INIT', getCards);
}

function* getById(action) {
    try {
    const token = yield select(selectors.auth.token);
    yield bindApiCall(actions.cards.getById, api.getById, token, action.payload);
    } catch (e) {
        openNotification('error', e.message, "Error");
    }
}

export function* getByIdSaga() {
    yield takeLatest('CARDS/GET_BY_ID_INIT', getById);
}