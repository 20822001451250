import { memo } from 'react'
import { Header } from './Header'

export const Layout = memo(({ children }) => {
	return (
		<>
			<Header />
			<div className='container-fluid page-body-wrapper'>
				<div className='content-wrapper'>
					<div>
						<div className='row'>
							<div className='col-md-12'>
								<div className='card'>
									<div className='card-body'>{children}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<footer className='footer'>
				<div className='d-sm-flex justify-content-center justify-content-sm-between'>
					<span className='text-muted text-center text-sm-left d-block d-sm-inline-block'>
						Copyright © 2020. All rights reserved.
					</span>
					<span className='float-none float-sm-right d-block mt-1 mt-sm-0 text-center'>
						Hand-crafted & made with
						<i className='ti-heart text-danger' />
					</span>
				</div>
			</footer>
		</>
	)
})
