export const setLoading = (payload) => ({
    type: 'UI/LOADING',
    payload,
});

export const log = (payload) => ({
    type: 'UI/LOG',
    payload
});

export const counterInc = () => ({
    type: 'UI/INC',
})

export const counterDec = () => ({
    type: 'UI/DEC',
})