import { takeLatest, select, put } from 'redux-saga/effects';
import {actions, selectors} from "../index";
import {bindApiCall, openNotification} from "./utils";
import { api } from "../api";

function* initApp() {
    const token = yield select(selectors.auth.token);

    try {
        yield bindApiCall(actions.session.check, api.check, token);
    } catch (e) {
        yield put(actions.auth.logout(e));
        yield put(actions.session.initApp.fail(e));
        openNotification('error', e.message, "Error");
        return;
    }

    try {
        yield bindApiCall(actions.session.getSpaces, api.spaces, token);
        yield bindApiCall(actions.session.getTypes, api.types, token);

        yield put(actions.session.initApp.done());
    } catch (e) {
        yield put(actions.session.initApp.fail(e));
        openNotification('error', e.message, "Error");
    }
}

export function* initAppSaga() {
    yield takeLatest('SESSION/INIT_APP_INIT', initApp);
}
