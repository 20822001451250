import React from 'react'

import QRCode from 'react-qr-code'
import 'App.css'

const Modal = (props) => {
	if (!props.show) {
		return null
	}
	return (
		<div className='modal' onClick={props.onClose}>
			<div className='modal-content' onClick={(e) => e.stopPropagation()}>
				<div className='modal-header'>
					<h4 className='modal-title'>QRCode</h4>
					<div className='modal-subtitle'>
						<h4 className='modal-title'>
							{props.currentCard.name} (
							{props.currentCard.version})
						</h4>
						<h4 className='modal-title'>
							{props.currentCard.bundle_id}
						</h4>
					</div>
				</div>
				<div className='modal-body'>
					<QRCode value={props.currentCard.download} />
				</div>
				<div className='modal-footer'>
					<button className='modal-button' onClick={props.onClose}>
						Close
					</button>
				</div>
			</div>
		</div>
	)
}

export default Modal
