import { createSelector } from "reselect";

export const state = (state) => state.session;

export const loading = createSelector(
    [state],
    session => session.loading,
);

export const error = createSelector(
    [state],
    session => session.error,
);

export const spaces = createSelector(
    [state],
    session => session.spaces,
);

export const types = createSelector(
    [state],
    session => session.types,
);

export const isInitialized = createSelector(
    [state],
    session => session.isInitialized,
);

export const currentSpaceId = createSelector(
    [state],
    session => session.currentSpaceId,
);

export const getCurrentSpace = createSelector(
    [state],
    session => {
        const { currentSpaceId, spaces } = session;

        if (!currentSpaceId) {
            return null;
        }

        return spaces.find(space => space.id === currentSpaceId) || null;
    }
)
