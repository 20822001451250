export const CardWrapper = ({ 
  image, 
  version, 
  bundle_version, 
  created_at,
  buttons
}) => {
  return (
    <div
      className='cards-wrapper'
      style={{
        alignItems: 'center',
        margin: '0 auto',
        flexDirection: 'column',
      }}
    >
      <div className='card-body card-body-middle-container'>
        <div
          className='card-body img-container'
          style={{
            maxWidth: 180,
            maxHeight: 180,
            minHeight: 180,
            minWidth: 180,
            padding: 10,
          }}
        >
          <img
            alt='card'
            className='product-img-outer'
            src={ image }
            style={{
              width: '100%',
              borderRadius: '10px',
              minWidth: 100,
            }}
          />
        </div>
        <p style={{ textAlign: 'center' }}>{ created_at }</p>
      </div>
      { buttons }
    </div>
  )
}