import {createAsyncAction} from "./utils";

export const check = createAsyncAction('AUTH/CHECK');

export const initApp = createAsyncAction('SESSION/INIT_APP');

export const getSpaces = createAsyncAction('SESSION/GET_SPACES');

export const getTypes = createAsyncAction('SESSION/GET_TYPES');


export const setSpaceId = (id) => ({
    type: 'SESSION/SET_SPACE_ID',
    payload: id,
});
