import { useLocation, useHistory } from 'react-router-dom'
import { useCallback, useMemo } from 'react'

export const useQuery = () => {
	const history = useHistory()
	const { search } = useLocation()
	const query = useMemo(() => new URLSearchParams(search), [search])

	const set = useCallback(
		(name, value) => {
			query.set(name, value)
			history.push(`?${query.toString()}`)
		},
		[query, history]
	)

	return { query, set }
}
