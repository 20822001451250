import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'store'
import { createStructuredSelector } from 'reselect'
import Modal from 'components/ui/Modal'
import CardModal from 'components/ui/CardModal'
import Select from 'react-select'
import GoBack from 'components/ui/GoBack'
import { CardHeadTitle } from "../../../components/ui/CardHeadTitle"
import { CardWrapper } from "../../../components/ui/CardWrapper"
import { Loader } from '../../../components/ui/Loader'
import { CardDownloadBtn } from 'components/ui/CardDownloadBtn'
import { CardQRBtn } from 'components/ui/CardQRBtn'
import { CardDeleteBtn } from 'components/ui/CardDeleteBtn'

const rootSelector = createStructuredSelector({
	isInitialized: selectors.session.isInitialized,
	currentCard: selectors.cards.currentCard,
	loading: selectors.cards.loading,
	session: selectors.session.spaces,
	main_page_redirect: selectors.deleteBuild.main_page_redirect,
	deleteLoading: selectors.deleteBuild.deleteLoading,
	finishedLoad: selectors.deleteBuild.finishedLoad,
})

export const CardPage = () => {
	const { cardId, spaces } = useParams()
	const dispatch = useDispatch()
	const { currentCard, loading, session } = useSelector(rootSelector)
	const [selectValue, setSelectValue] = useState('')
	const [show, setShow] = useState(false)
	const [checkboxValue, setCheckboxValue] = useState(false)
	const [showCardModal, setShowCardModal] = useState(false)
	const [curCard, setCurCard] = useState(false)

	const onChange = (e) => {
		e.value === 0 
			? window.history.pushState(null, null, `/${spaces}/${cardId}`)
			: window.history.pushState(null, null, `?version=${e.value}`)
		setSelectValue(e.value)
		dispatch(actions.cards.getById.init([cardId, e.value]))
	}

	const showCardModalHandler=(e, cur) => {
		e.preventDefault()
		e.stopPropagation()
		setShowCardModal( ( showCardModal ) => !showCardModal )
		setCurCard(cur)
	}

	const deleteCheckboxHandler = (e) => {
		e.stopPropagation()
		setCheckboxValue( (prevState) => !prevState )
	}

	const setCurCardHandler = (e, cur) => {
		  setCurCard( cur )
		  e.preventDefault()
		  e.stopPropagation()
		  setShow( ( show ) => !show )		
	}

	const deleteBtnHandler = (e, cur) => {
		e.stopPropagation()
		if ( checkboxValue ) {
			alert( 'Undeleted build')
		} else {
			dispatch( actions.deleteBuild.getDeleteBuild.init( cur.build_id ))
		}
	}

	const getCanDelete = (space_id) => {
		return ( session.length !== 0 && Number( space_id ) === Number( spaces ) && currentIsAdmin === 1)
	}

	const versions = currentCard ? currentCard.app_versions : null
	let options = []
	options.push({ value: 0, label: 'All versions' })
	if (versions !== null) {
		versions
			.sort((a, b) => b - a)
			.forEach((item) => {
				options.push({ value: item, label: item })
			})
	}

	let currentIsAdmin

	session.forEach((item) => {
		if (Number(item.id) === Number(spaces) && item.is_admin === 1) {
			currentIsAdmin = 1
		}
	})

	useEffect(() => {
		dispatch(actions.cards.getById.init(cardId))
	}, [cardId, dispatch])

	return (
		<div>
			<GoBack />
			{ 
				loading || !currentCard ? ( '' ) : (
					<div className='card-title'>
						<p
							className='product-title'
							style={{ marginBottom: '-5px', fontSize: '20px' }}
						>
							{currentCard.builds.name}
						</p>
						<p style={{ fontSize: '20px' }}>
							{currentCard.builds.bundle_id}
						</p>
					</div>
				)
			}
			<div
				className='col-lg-8 col-md-7 product-filter-options versions-select'
				style={{
					justifyContent: 'flex-end',
					marginBottom: 20,
					padding: '0px',
				}}
			>
				{
					currentCard && <Select
						placeholder='Versions'
						options={options}
						onChange={onChange}
					/>
				}
			</div>

		<div className='row' style={{ color: '#b1b1b5' }}>
			<CardModal
				showCardModal={showCardModal}
				currentCard={curCard}
				onClose = { () => setShowCardModal((showCardModal) => !showCardModal) }
			/>
			<Modal
				currentCard={curCard}
				show={show}
				onClose = { () => setShow((show) => !show) }
			/>
			<div className='col-md-12'>
				<div className='card'>
					<div>
						<div
							className='row product-item-wrapper stretch-card'
							style={{ display: 'flex', justifyContent: 'flex-start',}}
						>
							{ loading || !currentCard 
								? <Loader/> 
								: <> { Object.values( currentCard.builds ).map((cur) => {
										if (selectValue === cur.version || selectValue === '' || selectValue === 0) {
											return (<div
												className='col-xl-2 col-lg-3 col-md-3 col-sm-6 col-12 product-item stretch-card'
												key = { cur.build_id }
												style = {{
													display: 'flex',
													flexWrap: 'wrap',
													cursor: 'pointer',
												}}
												onClick={(e) => showCardModalHandler(e, cur)}
											>
											<div
												className='card'
												style={{ borderRadius: '10px',}}
											>
												<CardHeadTitle 
													name={cur.name} 
													bundle_id={cur.bundle_id}
													platformName={cur.type}
													version={cur.version} 
													bundle_version={cur.bundle_version} 
												/>
												<CardWrapper 
													image={cur.image}
													created_at={cur.created_at}  
													buttons={
														<div className='btn-container'>
															<CardDownloadBtn downloadHref={cur.download}/>
															<CardQRBtn onclickHandler={(e) => setCurCardHandler(e, cur)}/>
															<CardDeleteBtn canDelete={ getCanDelete(cur.space_id) } deleteBtnHandler={(e) => deleteBtnHandler(e, cur)}/>
														</div>
													}
												/>
												{ currentIsAdmin === 1 && (
													<div className='delete-check'>
														<input
															onClick={ (e) => deleteCheckboxHandler(e) }
															name='check'
															type='checkbox'
															value='Undeleted build'
														/>
													</div>) }
												</div>
											</div>)
										} else return ''
									})}	</> 
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
)
}
