import { createSelector } from "reselect";

export const state = (state) => state.auth;

export const isAuthorized = createSelector(
	[state],
	(auth) => auth.isAuthorized
);

export const check = createSelector([state], (auth) => auth.check);

export const loading = createSelector([state], (auth) => auth.loading);

export const error = createSelector([state], (auth) => auth.error);

export const token = createSelector([state], (auth) => auth.token);

export const name = createSelector([state], (auth) => auth.name);

export const email = createSelector([state], (auth) => auth.email);

export const notify = createSelector([state], (auth) => auth.notify);
