import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { selectors } from 'store'
import { useSelector } from 'react-redux'
import { Spin } from 'antd'

export const HomePage = () => {
	const spaces = useSelector(selectors.session.spaces)
	const history = useHistory()
	useEffect(() => {
		history.push(`/`)
	}, [spaces, history])

	return (
		<div>
			<Spin />
		</div>
	)
}
