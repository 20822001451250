import { fork, all } from "redux-saga/effects";

import { loadingSaga, logSaga } from "./ui";
import { loginSaga, logoutSaga } from "./auth";
import { initAppSaga } from "./session";
import { getByIdSaga, getCardsSaga } from "./cards";
import { getDeleteBuildSaga } from "./deleteBuild";
import { getNotifyUserChangeSaga, getNotifySpaceChangeSaga } from "./notify";

const allSagas = [
	logoutSaga,
	loadingSaga,
	logSaga,
	loginSaga,
	initAppSaga,
	getCardsSaga,
	getByIdSaga,
	getDeleteBuildSaga,
	getNotifyUserChangeSaga,
	getNotifySpaceChangeSaga,
];

export function* rootSaga() {
	yield all(allSagas.map((s) => fork(s)));
}
