export const initialState = {
	loading: null,
	error: null,
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case "NOTIFY/GET_USER_CHANGE_INIT":
			return {
				...state,
				error: null,
				loading: true,
			};
		case "NOTIFY/GET_USER_CHANGE_DONE":
			return {
				...state,
				loading: false,
			};
		case "NOTIFY/GET_USER_CHANGE_FAIL":
			return {
				...state,
				loading: false,
				error: action.error,
			};
		case "NOTIFY/GET_SPACE_CHANGE_INIT":
			return {
				...state,
				error: null,
				loading: true,
			};
		case "NOTIFY/GET_SPACE_CHANGE_DONE":
			return {
				...state,
				loading: false,
			};
		case "NOTIFY/GET_SPACE_CHANGE_FAIL":
			return {
				...state,
				loading: false,
				error: action.error,
			};
		default:
			return state;
	}
};
