import React from 'react'

const GoBack = () => {
	return (
		<div
			onClick={() => window.history.back()}
			style={{
				display: 'flex',
				cursor: 'pointer',
				fontSize: 20,
				maxWidth: 100,
				textDecoration: 'none',
			}}
		>
			<i
				className='typcn typcn-chevron-left'
				style={{ color: '#248afd', fontSize: 20 }}
			/>
			<div
				style={{
					color: '#b1b1b5',
					cursor: 'pointer',
					marginBottom: 20,
				}}
			>
				Go back
			</div>
		</div>
	)
}

export default GoBack
